export const tooltips = {
	newBoardGame:
		'Experiential engine suggests associations in games & event mechanics & audience motivations.',
	newBoardInnovative:
		'Innovative engine suggests associations in design & architecture, tech, sciences & engineering, infrastructure systems.',
	newBoardCreative: 'Creative Engine suggests associations in culture, lifestyle and trends.',
	heartedList:
		'You can check the list of the elements you like in Idea Box at any time and add it to your moodboard and notepad once you get to the Synthesise stage of your ideation.',
	search:
		"You can search for associated images and trending articles for the 'Creative' engine on the web by clicking search button; and further research papers, design awards, and business market analytics for the 'Innovative' engine; and games databases for the 'Experiential' engine.",
	generate:
		'Generate allows you to find taglines and haikus that are related to your concept.<br><br> Coming soon: generative images!',
	empathise:
		'The Empathise AI tool suggests different ideas from the AI, to help you empathise from different perspectives and standpoints, following the 6 Hats design-thinking tool',
	spark:
		"The Spark AI tool suggests 2 ideas based on the concept. These will be associations within the domains related to the engine you selected e.g. the 'Creative' engine, gives associations within culture, lifestyle and trends.",
	lateralThinking:
		'Lateral thinking is a way of solving problems using an indirect and creative approach. Slide lateral thinking sliders to get different perspectives.',
	empathiseFilter: 'You may apply a filter for a specific purpose.',
	analyses:
		'The recommended analyses are highlighted in blue. But you can also select different analyses from the menu by clicking them. You can click on one of the recommended analyses then the clusters will be generated by AI.',
	analysesAdditionalConcepts:
		'<strong>Include additional AI-suggested concepts closely associated with your selections</strong><br><br>This option enables more fine-grained analyses within the associated domain of knowledge. This may uncover more counter-intuitive association and illustrate macro-patterns',
	teamNote: 'Team Note allows you and other collaborators to create and share notes together.',
	perspective:
		'Perspectives show the word suggestions from a different perspective. Suggestions are labelled by colour: <br><span class="colour-demo GREEN">Bright Green - positive</span><br> <span class="colour-demo RED">Red - negative</span><br> <span class="colour-demo FUSCHIA">Pink - emotions</span><br> <span class="colour-demo BLUE">Blue - co-occurence</span><br><br> <span class="colour-demo FOREST">Dark Green</span>/<span class="colour-demo TAN">Tan</span>/<span class="colour-demo SALMON">Salmon</span>: different semantic and contextual associations.',
	boardGuideSpark:
		'Auto generate 2 ideas based on the selected concept. These will be associations within the domains related to the engine you selected e.g. the ‘Creative’ engine, gives associations within culture, lifestyle and trends',
	boardGuideEmpathise:
		'Different perspectives and ideas generated by the AI following the 6 Hats design-thinking tool.',
	boardGuideGenerate:
		'Generate semantic associated taglines, haikus and images of related terms to the selected word.',
	boardGuideLinker: 'Select multiple words to find and generate their connecting concepts.',
	boardGuideSearch: 'Search associated images and text on web of the chosen word.',
	boardGuideTheme:
		'AI analysis of the emerging themes and overall topic summaries and connections. This is derived from the entries in the Inspire board, and analyses the combination of both your entered ideas and AI suggestions. This can reveal some counter-intuitive patterns both for your personal ideation and for your whole team!',
	boardGuidePlot:
		'AI analyses of story plots that could be applicable to the ideas in the Inspire board. There are only 7-8 stories in the world, and you can use this tool to pick and choose elements to inform your storytelling in detail e.g. do you want to have elements of a Rebirth story combined with a Rags to Riches twist? Think of it like A/B testing before you deploy it into the world!',
	boardGuideArchetype:
		'AI analyses of different characterisations you could use with the tool of Archetypes. Everyone identifies with these universal archetypes at different points in their lives, with different products and contexts. You can use this tool to pick and choose elements to inform the characterisation of your storytelling e.g. is your target audience a mixture of the Explorer and the Hero, or is the character in your advert a nuance between Creator and Outlaw? Think of it like A/B testing before you deploy it into the world!',
	boardGuideMotivation:
		'AI analyses of different motivations for players, stakeholders, participants or attendees of the game, event and/or experience you have created. Why would someone want to experience this? For example, is it so that they can be challenged and learn something or maybe to be stimulated with novelty? What kind of excitement? You can use this tool to pick and choose elements to fine-tune the design of your experience. Think of it like A/B testing before you deploy it into the world!',
	boardGuidePersonality:
		'AI analyses of different characterisations you could use with the tool of Personalities. Every customer and/or brand has a certain personality. You can use this tool to shed light on communication styles to these different personalities, and associations that would appeal to them in your content creation, design and strategy. Think of it like A/B testing before you deploy it into the world!',
	boardGuideVisual:
		'AI analyses of different visual elements that you can explore with the tool of Visual. Your project will have visual associations of colour, texture and opacity that you can identify by analysing the language. For example if you are working on a film about sustainability, this tool will help you identify the visual look and feel of green and transparency to the set environment and lighting, and marketing materials etc. You can then interpret these general visual associations and work them into your project with creative freedom!',
	boardGuideMoodboard:
		'You can play with all the nuggets of inspiration you have collected in your Idea Box and find particularly interesting to the moodboard. You can then play around with these elements visually to build out your concepts',
	boardGuideStorytelling:
		'You can use Notepad in order to integrate the moodboard elements into a fuller synthesis of your idea.You could use storytelling i.e. who is the character in your story or what is the character of your brand, what is the story arc or main action, who is the target audience and what is their motivation for engaging in your creation, what are you communicating and to whom, and what is your tone-of-voice?',
	boardGuideShare:
		'Once you have synthesised your ideas, you can share with your team members. Share from your personal board/notepad or add directly.',
	inspireProgressNodes:
		'Number of <em>Nodes</em>. Each of the words or phrases on the board is a ‘node’. <br><br>Add at least 20 nodes to the board. You may add more than that.',
	inspireProgressConnectedness:
		'<em>Connectedness</em> measures the ratio of connections to nodes. Connect more nodes to increase connectedness. <br><br>To achieve a score of 100, create at least one connection for each node on the board.',
	inspireProgressDepth:
		'<em>Depth</em> measures whether you have explored concepts far from the original nodes. <br><br>To increase the <em>Depth</em> score use Spark or Empathise then use it again on the new suggestions and then once more on those suggestions. <br><br>At least 40% of the nodes (except those created via AI) need to be deeply explored in this way to achieve a score of 100',
	inspireProgressExploration:
		'<em>Exploration</em> measures whether you have explored widely around the board. <br><br>Use Spark or Empathise on more nodes to increase the <em>Exploration</em> score. <br><br>At least 70% of the nodes (except those created via AI) need to be explored to achieve a score of 100',
	inspireProgressAiTools:
		'To increase this score, use all the AI tools: Generate, Empathise, Spark, and Link.',
};

export const progressNudges = {
	exploration: 'Try using the Spark tool to generate more ideas!',
	depth:
		'Try to explore both broadly and deeply. Use Spark or Empathise on some the nodes you have generated using the AI tools!',
	aiTools: 'There are 4 AI tools: Spark, Empathise, Generate, and Link. Try to use them all!',
	connectedness:
		'Connect some of the nodes together where you see a relationship between the concepts',
	nodes: 'Almost there! Add a few more nodes to the board',
};
