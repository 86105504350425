import { EventEmitter } from 'events';

import { ClusterProps } from '../../types';
import { NCCluster, NCNode } from '../../../../src/commonTypes';

export const tutorialEmitter = new EventEmitter();

export const TUTORIAL_EVENT_TYPES = {
	createdNode: 'createdNode',
	openedNodeMenu: 'openedNodeMenu',
	aiEnabled: 'aiEnabled',
	openedProjectVisionPanel: 'openedProjectVisionPanel',
	openedAddToBoardPanel: 'openedAddToBoardPanel',
	addedWordsFromPanel: 'addedWordsFromPanel',
	afterAutoSpark: 'afterAutoSpark',
	clickedSpark: 'clickedSpark',
	clickedEmpathise: 'clickedEmpathise',
	clickedGenerate: 'clickedGenerate',
	clickedSynthesise: 'clickedSynthesise',
	clickedHaikus: 'clickedHaikus',
	clickedGenerateImage: 'clickedGenerateImage',
	clickedGenerateImageResult: 'clickedGenerateImageResult',
	clickedBrainstorm: 'clickedBrainstorm',
	clickedBrainstormResults: 'clickedBrainstormResults',
	clickedCommonIdeas: 'clickedCommonIdeas',
	clickedCommonIdeasResults: 'clickedCommonIdeasResults',
	clickedGenerateUserPersona: 'clickedGenerateUserPersona',
	clickedSearch: 'clickedSearch',
	clickedLink: 'clickedLink',
	clickedMerge: 'clickedMerge',
	clickedConnect: 'clickedConnect',
	openedIdeaBox: 'openedIdeaBox',
	openedInvite: 'openedInvite',
	closedInvite: 'closedInvite',
	invitedCollaborator: 'invitedCollaborator',
	selectedNode: 'selectedNode',
	addedTwoNodes: 'addedTwoNodes',
	addedSingleNode: 'addedSingleNode',
	addedCluster: 'addedCluster',
	likedNode: 'likedNode',
	likedResult: 'likedResult',
	likedCluster: 'likedCluster',
	mergedNodes: 'mergedNodes',
	connectedNodes: 'connectedNodes',
	updatedLabel: 'updatedLabel',
	afterNewAnalysis: 'afterNewAnalysis',
	openedNotepad: 'openedNotepad',
	addedToNote: 'addedToNote',
	selectingItems: 'selectingItems',
	doneSelectingItems: 'doneSelectingItems',
	synthesiseResult: 'synthesiseResult',
	openedExport: 'openedExport',
	openedPresentationPreview: 'openedPresentationPreview',
};

export const userCreatedNode = (node: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('createdNode', { node });
	}, 400);
};

export const openedNodeMenu = (node: NCNode) => {
	tutorialEmitter.emit('openedNodeMenu', { node });
};

export const aiEnabled = () => {
	tutorialEmitter.emit('aiEnabled');
};

export const clickedSpark = (node: NCNode) => {
	tutorialEmitter.emit('clickedSpark', { node });
};

export const clickedEmpathise = (node: NCNode) => {
	tutorialEmitter.emit('clickedEmpathise', { node });
};

export const clickedGenerate = (node: NCNode) => {
	tutorialEmitter.emit('clickedGenerate', { node });
};

export const clickedSynthesise = (node: NCNode) => {
	tutorialEmitter.emit('clickedSynthesise', { node });
};

export const clickedHaikus = () => {
	tutorialEmitter.emit('clickedHaikus', {});
};

export const clickedGenerateUserPersona = () => {
	tutorialEmitter.emit('clickedGenerateUserPersona', {});
};

export const clickedGenerateImage = () => {
	tutorialEmitter.emit('clickedGenerateImage', {});
};

export const clickedGenerateImageResult = () => {
	tutorialEmitter.emit('clickedGenerateImageResult', {});
};

export const clickedBrainstorm = () => {
	tutorialEmitter.emit('clickedBrainstorm', {});
};

export const clickedBrainstormResults = () => {
	tutorialEmitter.emit('clickedBrainstormResults', {});
};

export const clickedCommonIdeas = () => {
	tutorialEmitter.emit('clickedCommonIdeas', {});
};

export const clickedCommonIdeasResults = () => {
	tutorialEmitter.emit('clickedCommonIdeasResults', {});
};

export const clickedSearch = (node: NCNode) => {
	tutorialEmitter.emit('clickedSearch', { node });
};

export const clickedLink = (node: NCNode) => {
	tutorialEmitter.emit('clickedLink', { node });
};

export const clickedMerge = (node: NCNode) => {
	tutorialEmitter.emit('clickedMerge', { node });
};

export const clickedConnect = (node: NCNode) => {
	tutorialEmitter.emit('clickedConnect', { node });
};

export const openedIdeaBox = () => {
	tutorialEmitter.emit('openedIdeaBox', {});
};

export const openedInvite = () => {
	tutorialEmitter.emit('openedInvite', {});
};

export const closedInvite = () => {
	tutorialEmitter.emit('closedInvite', {});
};

export const invitedCollaborator = () => {
	tutorialEmitter.emit('invitedCollaborator', {});
};

export const selectedNode = (node: NCNode) => {
	tutorialEmitter.emit('selectedNode', { node });
};

export const mergedNodes = (node: NCNode) => {
	tutorialEmitter.emit('mergedNodes', { node });
};

export const connectedNodes = (node: NCNode) => {
	tutorialEmitter.emit('connectedNodes', { node });
};

export const addedTwoNodes = (firstNode: NCNode, secondNode: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedTwoNodes', { firstNode, secondNode });
	}, 400);
};

export const addedSingleNode = (node: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedSingleNode', { node });
	}, 400);
};

export const addedCluster = (cluster: NCCluster, props: ClusterProps) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedCluster', { cluster, props });
	}, 50);
};

export const likedNode = (node: NCNode) => {
	tutorialEmitter.emit('likedNode', { node });
};

export const likedResult = () => {
	tutorialEmitter.emit('likedResult', {});
};

export const likedCluster = (cluster: NCCluster) => {
	tutorialEmitter.emit('likedCluster', { cluster });
};

export const openedProjectVisionPanel = () => {
	tutorialEmitter.emit('openedProjectVisionPanel', {});
};

export const openedAddToBoardPanel = () => {
	tutorialEmitter.emit('openedAddToBoardPanel', {});
};

export const addedWordsFromPanel = (nodes: Array<NCNode>) => {
	tutorialEmitter.emit('addedWordsFromPanel', { nodes });
};

export const afterAutoSpark = (nodes: Array<NCNode>) => {
	tutorialEmitter.emit('afterAutoSpark', { nodes });
};

export const updatedLabel = (node: NCNode) => {
	tutorialEmitter.emit('updatedLabel', { node });
};

export const loadedNewAnalysis = () => {
	tutorialEmitter.emit('afterNewAnalysis', {});
};

export const openedNotepad = () => {
	tutorialEmitter.emit('openedNotepad', {});
};

export const openedExport = () => {
	tutorialEmitter.emit('openedExport', {});
};

export const openedPresentationPreview = () => {
	tutorialEmitter.emit('openedPresentationPreview', {});
};

export const addedToNote = (node: NCNode) => {
	tutorialEmitter.emit('addedToNote', { node });
};

export const selectingItems = () => {
	tutorialEmitter.emit('selectingItems', {});
};

export const doneSelectingItems = () => {
	tutorialEmitter.emit('doneSelectingItems', {});
};

export const synthesiseResult = () => {
	tutorialEmitter.emit('synthesiseResult', {});
};
