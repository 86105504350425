import Quill from 'quill';
import Delta from 'quill-delta';
import * as sharedb from 'sharedb';

import Graph from './Graph';
import { showAlert } from '../ui/uiUtils';
import { openNotepad } from '../ui/notepad';
import * as hints from '../tutorials/hints';
import { startSynthesiseTutorial } from '../tutorials/synthesiseTutorial';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import * as templateTutorial from '../tutorials/templateTutorial';
import {
	AvailableColour,
	NCGraphData,
	NCNode,
	BoardUser,
	GraphMode,
	UserSelection,
} from '../../../../src/commonTypes';
import { openSynthesiseAiPanel, openSynthesiseAiPanelWithImage } from '../ui/synthesiseAi';
import { uploadBoardImage } from '../apiRequests';
import { tutorialEmitter } from '../tutorials/tutorialEvents';

class MoodboardGraph extends Graph {
	public quill: Quill;

	constructor(
		data: NCGraphData,
		boardUsers: Array<BoardUser>,
		userSelections: Array<UserSelection>,
		colourClass: AvailableColour,
		colourHex: string,
		isCollaborative: boolean,
		automation: boolean,
		notesDoc: sharedb.Doc
	) {
		super(data, boardUsers, userSelections, colourClass, colourHex, isCollaborative, automation);

		document.querySelector('#notepad-container h2')!.innerHTML = isCollaborative
			? 'Team Note'
			: 'Personal Note';
		const shareNoteButton = document.querySelector<HTMLButtonElement>('#share-note')!;
		const notePadLoadingIndicator = document.querySelector<HTMLDivElement>(
			'#notepad-container .loading'
		)!;
		if (isCollaborative) {
			shareNoteButton.classList.add('hidden');
		} else {
			shareNoteButton.classList.remove('hidden');
			shareNoteButton.onclick = (ev) => {
				ev.preventDefault();
				// Show notepad loading indicator when user clicks 'Share to team notepad' button
				notePadLoadingIndicator.classList.remove('hidden');

				const selection = this.quill.getSelection();
				if (selection && selection.length > 0) {
					const contentDelta = this.quill.getContents(selection.index, selection.length);
					if (globalThis.neuroCreate.switchToMode) {
						globalThis.neuroCreate.switchToMode('moodboard', true, automation, () => {
							window.location.hash = '#synthesise-team';
							setTimeout(() => {
								// Insert selected text into team notepad
								const teamGraph = globalThis.neuroCreate.graph as MoodboardGraph;
								teamGraph.quill.updateContents(
									new Delta([
										{ retain: teamGraph.quill.getLength() },
										{ insert: '\n' },
										...contentDelta.ops,
									]),
									'user'
								);
								// Scroll to bottom
								const qlEditor = document.querySelector('.ql-editor')!;
								qlEditor.scrollTop = qlEditor.scrollHeight;
							}, 100);
						});

						// Hide notepad loading indicator once the team notepad is ready
						setTimeout(() => {
							notePadLoadingIndicator.classList.add('hidden');
						}, 500);
					}
				} else {
					// Hide notepad loading indicator immediately if no text is selected
					notePadLoadingIndicator.classList.add('hidden');
					showAlert(
						'Please first select some text in the notepad to share to the team notepad.<br><br>Then, press the share button again and the selected text will be copied into the team notepad.'
					);
				}
			};
		}

		document.querySelector('#notepad-container .ql-toolbar')?.remove();

		this.quill = new Quill('#editor', {
			theme: 'snow',
			modules: {
				toolbar: {
					container: [
						[{ header: 1 }, { header: 2 }, 'bold', 'italic', 'underline', 'strike', { color: [] }],
						['image'],
					],
					handlers: {
						image: this.quillImageHandler.bind(this),
					},
				},
			},
		});
		this.quill.on('text-change', (delta, oldDelta, source) => {
			if (source !== 'user') return;

			notesDoc.submitOp(delta, { source: this.quill });
		});

		notesDoc.subscribe((err) => {
			if (err) {
				throw err;
			}

			if (notesDoc.data === undefined) {
				notesDoc.create([{ insert: '' }], 'http://sharejs.org/types/rich-text/v1');
				console.log('Created empty notes');
			}

			this.quill.setContents(notesDoc.data as ReturnType<typeof this.quill.setContents>);

			notesDoc.on('op', (op, source) => {
				if (source === this.quill) {
					return;
				}

				this.quill.updateContents(op as unknown as ReturnType<typeof this.quill.setContents>);
			});
		});
	}

	quillImageHandler() {
		const input = document.createElement('input');

		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/png,image/jpeg');
		input.click();

		input.onchange = async () => {
			const file = input.files && input.files[0];
			if (file) {
				// Save current cursor state
				const range = this.quill.getSelection(true);

				// Insert temporary loading image
				this.quill.insertEmbed(
					range.index,
					'image',
					`${window.location.origin}/assets/loading-large.gif`
				);

				// Move cursor to right side of image (easier to continue typing)
				this.quill.setSelection({ index: range.index + 1, length: 0 });

				const imageUrl = await uploadBoardImage(document.body.dataset.boardId!, file);

				// Remove loading image
				this.quill.deleteText(range.index, 1);

				// Insert uploaded image & save
				this.quill.insertEmbed(range.index, 'image', imageUrl, 'user');
			}
		};
	}

	isEditable(): boolean {
		return !this.activeNode || this.isActiveNodeOwnedByCurrentUser();
	}

	getCurrentlySelectedText(): string {
		const selection = this.quill.getSelection();
		if (selection && selection.length > 1) {
			const contentDelta = this.quill.getText(selection.index, selection.length);
			return contentDelta.replace(/\n+/g, ' ');
		}
		return '';
	}

	validate(): boolean {
		if (this.automation) {
			// Skip possible alerts
			return true;
		}
		const likedItems = document.querySelector('#hearted-container .like-items');
		if (!this.isCollaborative && likedItems && likedItems.innerHTML.includes('Empty list!')) {
			showAlert('Please add some items to your Idea Box, in order to use Synthesise mode.');
			return false;
		}
		return true;
	}

	addSelectionToMoodboard(selection: string | { url: string }): NCNode {
		if (typeof selection === 'string') {
			return this.addNodeNearPoint({ x: -380, y: 50 }, selection, undefined, undefined);
		} else {
			return this.addNodeNearPoint({ x: -380, y: 50 }, undefined, selection.url, undefined);
		}
	}

	addToNote(node: NCNode): void {
		openNotepad();

		const teamGraph = globalThis.neuroCreate.graph as MoodboardGraph;
		if (node.label) {
			// Insert node text into team notepad
			const delta = new Delta([
				{ retain: teamGraph.quill.getLength() },
				{ insert: '\n' },
				{ insert: node.label },
			]);
			if (node.href) {
				delta.push({ insert: '\n' });
				delta.push({ insert: node.href });
			}
			teamGraph.quill.updateContents(delta, 'user');
		} else if (node.src) {
			teamGraph.quill.updateContents(
				new Delta([
					{ retain: teamGraph.quill.getLength() },
					{ insert: '\n' },
					{ insert: { image: node.src } },
				]),
				'user'
			);
		}
		// Scroll to bottom
		const qlEditor = document.querySelector('.ql-editor')!;
		qlEditor.scrollTop = qlEditor.scrollHeight;

		tutorialEvents.addedToNote(node);
	}

	copyImageToNote(text: string): void {
		// Insert image into notepad
		const delta = new Delta([
			{ retain: this.quill.getLength() },
			{ insert: '\n' },
			{ insert: { image: text } },
		]);
		this.quill.updateContents(delta, 'user');
		// Scroll to bottom
		const qlEditor = document.querySelector('.ql-editor')!;
		qlEditor.scrollTop = qlEditor.scrollHeight;
	}

	copyToNote(text: string): void {
		// Insert text into notepad
		const delta = new Delta([
			{ retain: this.quill.getLength() },
			{ insert: '\n' },
			{ insert: text },
		]);
		this.quill.updateContents(delta, 'user');
		// Scroll to bottom
		const qlEditor = document.querySelector('.ql-editor')!;
		qlEditor.scrollTop = qlEditor.scrollHeight;
	}

	getMenuOptions() {
		if (this.isCollaborative) {
			return [
				{ label: 'Like', enabled: true, longText: true, shortText: true },
				{ label: 'Synthesise', enabled: true, requiresAi: true, longText: true, shortText: true },
				{ label: 'Note', enabled: true, longText: true, shortText: true },
				{ label: 'Connect', enabled: true, longText: true, shortText: true },
				{ label: 'View', enabled: !!this.activeNode?.src, shortText: true },
			];
		} else {
			return [
				{ label: 'Like', enabled: true, longText: true, shortText: true },
				{ label: 'Synthesise', enabled: true, requiresAi: true, longText: true, shortText: true },
				{ label: 'Note', enabled: true, longText: true, shortText: true },
				{ label: 'Share', enabled: true, longText: true, shortText: true },
				{ label: 'Connect', enabled: true, longText: true, shortText: true },
				{ label: 'View', enabled: !!this.activeNode?.src, shortText: true },
			];
		}
	}

	/**
	 * Share this node to team board
	 */
	share = (node: NCNode): void => {
		if (globalThis.neuroCreate.switchToMode) {
			globalThis.neuroCreate.switchToMode('moodboard', true, this.automation, () => {
				window.location.hash = '#synthesise-team';

				if (node.label || node.src) {
					const position = { x: node.x, y: node.y };
					const teamGraph = globalThis.neuroCreate.graph as MoodboardGraph;
					teamGraph.addNodeNearPoint(
						position,
						node.label || undefined,
						node.src,
						node.href,
						node.likes && node.likes[this.userColourClass]
					);
				}
			});
		}
	};

	areNodesColoured() {
		return true;
	}

	get nodeRadius() {
		return 64;
	}

	get graphMode(): GraphMode {
		return 'moodboard';
	}

	startTutorial(): void {
		super.startTutorial();
		if (this.templateId) {
			templateTutorial.start(this.templateId, this, true);
		} else {
			startSynthesiseTutorial();
		}
	}

	startHints() {
		if (this.nodes.length > 0) {
			this.setHintsShown();
		} else if (!this.currentUser.settings.shown_synthesise) {
			hints.beginSynthesiseHint();
		}
	}

	setHintsShown() {
		super.setHintsShown();
		this.currentUser.settings.shown_synthesise = true;
		this.saveUser();
	}

	getHintsShown(): boolean {
		return this.currentUser.settings.shown_synthesise || false;
	}

	synthesise(node: NCNode): void {
		if (node.src) {
			openSynthesiseAiPanelWithImage(node.src || '');
		} else {
			openSynthesiseAiPanel(node.label || '');
		}
		tutorialEvents.clickedSynthesise(node);
	}
}

export default MoodboardGraph;
