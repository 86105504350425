import { removeChildren } from './uiUtils';
import { getHeartIcon } from './toolsUI';
import { NCNode, SearchEntry } from '../../../../src/commonTypes';
import tippy, { followCursor, hideAll, roundArrow } from 'tippy.js';
import { InspireGraph } from '../graphs';
import { saveBoardImageFromUrl } from '../apiRequests';

export const resetSearch = () => {
	const container = document.querySelector('#search-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	const noResults = container.querySelector('.no-results')!;
	const resultsList = container.querySelector('.results-list')!;
	removeChildren(resultsList);
	loadingIndicator.classList.remove('hidden');
	noResults.classList.add('hidden');
};

export const showSearchResults = (entries: Array<SearchEntry>, node: NCNode) => {
	const container = document.querySelector('#search-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	const noResults = container.querySelector('.no-results')!;
	const resultsList = container.querySelector('.results-list')!;

	loadingIndicator.classList.add('hidden');

	for (const entry of entries) {
		const listItem = document.createElement('li');

		if (entry.type === 'article') {
			const searchResultDiv = document.createElement('div');
			searchResultDiv.className = 'search-result';
			const title = document.createElement('div');
			title.className = 'title';
			if (entry.title) {
				title.innerText = entry.title;
			}
			searchResultDiv.append(title);

			const text = document.createElement('div');
			text.className = 'text';
			if (entry.snippet) {
				text.innerText = entry.snippet;
			}
			searchResultDiv.append(text);
			listItem.append(searchResultDiv);
		} else if (entry.type?.startsWith('image') && entry.url) {
			listItem.style.backgroundImage = `url(${entry.url})`;
		}

		const link = document.createElement('a');
		link.target = '_blank';
		if (entry.url) {
			link.href = entry.url;
		}
		link.innerText = entry.displayLink || entry.url || 'Link';
		listItem.append(link);

		const textToSave = `${entry.title || ''}\n\n${entry.snippet || ''}`;

		listItem.append(
			getHeartIcon(
				entry.type === 'image' ? 'search-image' : 'search-page',
				() => (entry.type === 'image' ? null : entry.title) || null,
				entry.url,
				textToSave
			)
		);

		if (neuroCreate.graph?.graphMode !== 'ideate') {
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			listItem.addEventListener('click', async (e) => {
				const { nodeName, parentElement } = e.target as HTMLElement;
				const isHeartIconClicked = nodeName === 'IMG' && parentElement?.classList.contains('heart');
				const isLink = nodeName === 'A';
				const graph = globalThis.neuroCreate.graph as InspireGraph;
				if (!isHeartIconClicked && !isLink && graph) {
					if (entry.type === 'image') {
						// Add image to board
						const url = await saveBoardImageFromUrl(document.body.dataset.boardId!, entry.url);
						graph.addSingleNode(node, '', undefined, [], url);
					} else {
						graph.addSingleNode(node, textToSave);
					}
				}
			});

			const tooltip = tippy(listItem, {
				theme: 'neuro',
				arrow: roundArrow,
				delay: 0,
				duration: 0,
				offset: [0, 30],
				placement: 'bottom',
				followCursor: true,
				content: 'Add to board',
				plugins: [followCursor],
			});
			listItem?.addEventListener('mouseover', (e) => {
				const { nodeName, parentElement } = e.target as HTMLElement;
				const isHeartIconHovered = nodeName === 'IMG' && parentElement?.classList.contains('heart');
				const isLink = nodeName === 'A';
				if (!isHeartIconHovered && !isLink) {
					tooltip.show();
				} else {
					hideAll();
				}
				return;
			});
		}

		resultsList.append(listItem);
	}

	if (entries.length === 0) {
		noResults.classList.remove('hidden');
	}
};
