import { ButtonDefinition, showAlert } from './uiUtils';
import { ENGINE_TYPES } from '../../../../src/commonConstants';
import { EngineId } from '../../../../src/commonTypes';
import { updateBoardEngine } from '../apiRequests';
import { isBoardOwner } from './toolsUI';

const boardEngineButton = document.querySelector('#engine-select');
if (boardEngineButton) {
	boardEngineButton.addEventListener('click', () => {
		if (!isBoardOwner()) {
			showAlert('Only the board owner can change the board engine');
			return;
		}

		const { engine: engineId } = document.body.dataset as { engine: EngineId };
		const engine = Object.values(ENGINE_TYPES).find((e) => e.id === engineId)!;
		const remainingEngines = Object.values(ENGINE_TYPES).filter((e) => e !== engine);
		showAlert(
			`Currently using the ${engine.name} Engine`,
			remainingEngines.map(
				(e) =>
					({
						label: `Switch to ${e.name}`,
						iconUrl: `/assets/icon/dashboard/${e.id.toLowerCase()}.png`,
						onClick: () => {
							boardEngineButton.classList.remove(`border-${engineId.toLowerCase()}`);
							updateBoardEngine(document.body.dataset.boardId!, e.id);
						},
					}) as ButtonDefinition
			)
		);
	});
}
