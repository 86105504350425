import { startTutorial } from './tutorial';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import { showWordInputPanel } from '../ui/uiUtils';
import { InspireGraph } from '../graphs';
import { getPointsSortedByClosestToCenter } from '../graphs/geometryUtils';
import { ensureMenuIsOpen } from '../ui/menuUi';
import { getInviteStep, shouldShowInviteTutorial, useInviteTutorial } from './inviteTutorialUtils';
import { IntroStep } from 'intro.js/src/core/steps';

/**
 * When a tutorial is not running we still run a light tutorial with "hints" or "nudges".
 */

export const beginInspireHint = () => {
	tutorialEmitter.removeAllListeners();
	showWordInputPanel();
	tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterAutoSpark, afterAutoSpark);

	// Auto-spark can be triggered in various ways... filling in the input panel, toggling AI, or added a node with AI on.
	// However it should only happen once.
	tutorialEmitter.on(TUTORIAL_EVENT_TYPES.updatedLabel, updatedLabel);
	const autoSpark = () => {
		if (globalThis.neuroCreate.graph?.graphMode === 'inspire') {
			const graph = globalThis.neuroCreate.graph as InspireGraph;
			graph.autoSpark();
			tutorialEmitter.off(TUTORIAL_EVENT_TYPES.updatedLabel, updatedLabel);
			tutorialEmitter.off(TUTORIAL_EVENT_TYPES.addedWordsFromPanel, updatedLabel);
			tutorialEmitter.off(TUTORIAL_EVENT_TYPES.aiEnabled, updatedLabel);
		}
	};
	tutorialEmitter.once(TUTORIAL_EVENT_TYPES.aiEnabled, autoSpark);
	tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedWordsFromPanel, autoSpark);
};

const updatedLabel = (): void => {
	if (globalThis.neuroCreate.graph?.graphMode === 'inspire') {
		const graph = globalThis.neuroCreate.graph as InspireGraph;
		if (graph.isAiEnabled()) {
			graph.autoSpark();
			tutorialEmitter.off(TUTORIAL_EVENT_TYPES.updatedLabel, updatedLabel);
		} else {
			if (graph.labels.join(' ').split(' ').length >= 10) {
				nudgeAiToggle();
			}
		}
	}
};

const nudgeAiToggle = () => {
	tutorialEmitter.off(TUTORIAL_EVENT_TYPES.updatedLabel, updatedLabel);
	startTutorial({
		disableInteraction: false,
		steps: [
			{
				title: `Ready to take it to the next level?`,
				tooltipClass: 'medium-tooltip',
				element: '#ai-toggle-button',
				intro: `Turn on the AI slider to turbo-charge your conceptual ideation with nuggets of inspiration!`,
			},
		],
	});
};

const afterCloseInvite = () => {
	if (globalThis.neuroCreate.graph) {
		const nodes = getPointsSortedByClosestToCenter(globalThis.neuroCreate.graph.nodes).filter(
			(n) => !n.dotted
		);
		const nodeToHighlight = nodes[nodes.length - 1];

		startTutorial(
			{
				disableInteraction: false,
				steps: [
					{
						title: `Idea Box`,
						intro: `Add and collect any concept you like to your <strong>Idea Box</strong>, which will be a repository for your conceptual nuggets of inspiration to use later`,
						element: '#board-menu-hearted',
					},
					{
						title: `Try expanding on me!`,
						intro: `Click on the node and use <strong>Spark</strong> or one of the other AI tools`,
						element: `#node-${nodeToHighlight.uid}`,
					},
				],
			},
			{
				onexit: () => {
					if (globalThis.neuroCreate.graph) {
						globalThis.neuroCreate.graph.setHintsShown();
					}
				},
				onbeforechange: ensureMenuIsOpen,
				onafterchange: (target) => {
					if (globalThis.neuroCreate.graph && target.id === `node-${nodeToHighlight.uid}`) {
						globalThis.neuroCreate.graph.centerTo(nodeToHighlight);
					}
				},
			}
		);
	}
};

const afterOpenInvite = useInviteTutorial(afterCloseInvite);

const afterAutoSpark = () => {
	const shouldShowInvite = shouldShowInviteTutorial();

	if (shouldShowInvite) {
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedInvite, afterOpenInvite);
	}

	const steps: Array<Partial<IntroStep>> = [
		{
			title: `AI is enabled`,
			element: '#ai-toggle-button',
			intro: `FlowCreate AI turbo-charges your conceptual ideation with nuggets of inspiration!
<br><br>
However, you can turn it off when you prefer to work without assistance.`,
		},
	];

	if (shouldShowInvite) {
		steps.push(getInviteStep());
	}

	startTutorial(
		{
			disableInteraction: false,
			steps,
		},
		{
			onbeforechange: ensureMenuIsOpen,
			onafterchange: (target) => {
				if (target.id === `ai-toggle-button`) {
					if (!globalThis.neuroCreate.graph?.isAiEnabled()) {
						document.querySelector<HTMLInputElement>('#ai-setting')!.click();
					}
				}
			},
			onexit: shouldShowInvite ? afterOpenInvite : afterCloseInvite,
		}
	);
};

const afterCloseInviteIdeate = () => {
	if (globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.setHintsShown();
	}
};

const afterOpenInviteIdeate = useInviteTutorial(afterCloseInviteIdeate);

export const beginIdeateHint = () => {
	const shouldShowInvite = shouldShowInviteTutorial();
	if (!shouldShowInvite) {
		return;
	}

	tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedInvite, afterOpenInviteIdeate);

	startTutorial(
		{
			disableInteraction: false,
			steps: [getInviteStep()],
		},
		{
			onbeforechange: ensureMenuIsOpen,
			onexit: afterOpenInviteIdeate,
		}
	);
};

const afterCloseInviteSynthesise = () => {
	if (globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.setHintsShown();
	}
};

const afterOpenInviteSynthesise = useInviteTutorial(afterCloseInviteSynthesise);

export const beginSynthesiseHint = () => {
	const steps: Array<Partial<IntroStep>> = [
		{
			title: `Welcome to Synthesise`,
			tooltipClass: 'medium-tooltip',
			element: '#board-menu-hearted',
			intro: `Open your Ideas Box to add any nuggets of inspiration you find particularly interesting to the moodboard, so you can play around with these elements visually to build out your concepts`,
		},
		{
			title: `Notepad`,
			element: '#notepad-button',
			intro: `Now Open the <strong>Notepad</strong> in order to integrate the moodboard elements into a fuller synthesis of your idea.`,
		},
		{
			title: `Using the Notepad`,
			element: '#notepad-button',
			intro: `
 You could use different approaches such as storytelling i.e. who is the character, what's the story arc or main action?
 <br><br>
 Or if you are looking at branding or product design, what is the character of your brand, who is the target audience and what's their motivation for engaging in your creation?
 <br><br>
 Or for marketing what are you communicating and to whom, what is your tone-of-voice?
 <br><br>
 You could use different techniques to help you start, frame and re-frame such as SCAMPER: Substitute, Combine, Adapt, Modify/Magnify, Purpose, Eliminate/Minimize and Rearrange/Reverse, or strategy and innovation analysis PESTEL: Political, Economic, Social, Technological, Environmental, and Legal factors`,
		},
	];

	const shouldShowInvite = shouldShowInviteTutorial();
	if (shouldShowInvite) {
		steps.push(getInviteStep());
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedInvite, afterOpenInviteSynthesise);
	}

	startTutorial(
		{
			disableInteraction: false,
			steps,
		},
		{
			onexit: shouldShowInvite
				? afterOpenInviteSynthesise
				: () => {
						if (globalThis.neuroCreate.graph) {
							globalThis.neuroCreate.graph.setHintsShown();
						}
				  },
			onbeforechange: ensureMenuIsOpen,
		}
	);
};
